// import './App.css';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import React from "react";
import Modal from 'react-modal';
import BigCalendar from "./BigCalendar";
import moment from "moment";
import axios from 'axios'
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

function App() {
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    async function handleUpdateCalendar(data) {
        let myCalendar = data.slice()
        for (let i = 0; i < myCalendar.length; i++) {
            myCalendar[i].start = moment(myCalendar[i].start).format("YYYY-MM-DD")
            myCalendar[i].end = moment(myCalendar[i].end).format("YYYY-MM-DD")
        }
        closeModal()
        await axios.post('/calendar', myCalendar)
            .then(response => console.log(response.data));

    }

    return (
        <>
            <button type="button" className="btn btn-default pull-right" onClick={openModal}><span
                className="glyphicon glyphicon-calendar"></span> Calendar
            </button>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                // style={customStyles}
                contentLabel="Example Modal"
            >

                <BigCalendar onSave={(data)=>handleUpdateCalendar(data)} onClose={closeModal}/>

            </Modal>

        </>

    );
}

export default App;
