import React, { Fragment, useState, useCallback, useMemo,useEffect } from 'react'
import moment from 'moment'
import axios from 'axios'
import { Calendar, Views, DateLocalizer,
    momentLocalizer, } from 'react-big-calendar'
const mLocalizer = momentLocalizer(moment)
export default function BigCalendar({onSave,onClose}) {
    let localizer = mLocalizer
    const [isLoading, setLoading] = useState(true)
    useEffect (() => {
        const getItems =  async () =>{
            const result = await axios (
                `/calendar` //Endpoint and parameter or base Url
            )
            if(result.data.success){
                setEvents(result.data.data)
            }

            // setEvents(result.data)//sets the data to appear
            // setLoading(false) //stop loading when data is fetched
        }
        getItems()

    }, [])//when we use useEffect we put dependency as a second paramers

    const [myEvents, setEvents] = useState( [

    ])

    const handleSelectSlot = useCallback(
        ({ start, end }) => {
            const title = window.prompt('Add Holiday Event')
            if (title) {
                setEvents((prev) => [...prev, { start, end, title, id:Date.now()}])
            }
        },
        [setEvents]
    )

    const handleSelectEvent = useCallback(
        (event) => {
            let text = `${event.title}\nAre you sure to delete this event?`;
            if (window.confirm(text) == true) {
              let newEvents = []
                myEvents.map((item)=>{
                    console.log(item.id,event.id)
                    if(event.id !== item.id){
                        newEvents.push(item)
                    }
                })
                setEvents(newEvents)
            }
        },
        [myEvents]
    )

    const { defaultDate, scrollToTime } = useMemo(
        () => ({
            defaultDate: new Date(2015, 3, 12),
            scrollToTime: new Date(1970, 1, 1, 6),
        }),
        []
    )
    console.log(myEvents)

    return (
        <Fragment>
            {/*<div className="height600">*/}
                {/*<Calendar
                    defaultDate={defaultDate}
                    defaultView={Views.MONTH}
                    events={myEvents}
                    onSelectEvent={handleSelectEvent}
                    onSelectSlot={handleSelectSlot}
                    selectable
                    localizer={localizer}
                    scrollToTime={scrollToTime}
                />*/}
            {/*</div>*/}
            <div style={{position:'absolute',right:20}}>
                <button type="button" className="btn btn-default pull-right"  style={{marginLeft:5}} onClick={()=>onClose()} ><span
                    className="glyphicon glyphicon-remove-circle"></span> Close
                </button>
                <button type="button" className="btn btn-default pull-right"  onClick={()=>onSave(myEvents)} ><span
                    className="glyphicon glyphicon-calendar"></span> Save
                </button>
            </div>

            <Calendar
                localizer={localizer}
                defaultDate={new Date()}
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                defaultView="month"
                selectable
                events={myEvents}
                // style={{ height: "100vh" }}
                views={
                    {
                        month: true,
                    }}
            />
        </Fragment>
    )
}
